import * as React from "react"
import Moment from 'react-moment'
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import gsap from 'gsap'
import { StaticImage } from "gatsby-plugin-image"
import { Script } from "gatsby"
import "../styles/pages/tour.scss"

const TourPage = ({ transitionStatus }) => {
  React.useEffect(() => {
    if (transitionStatus === 'entering') {
      gsap.to('.tour-page', {
        autoAlpha: 1,
        duration: 1, //if we are entering the page, let's make the div with class .tour-page visible in one second
      });
    }
    if (transitionStatus === 'exiting') {
      gsap.to('.tour-page', { autoAlpha: 0, duration: 1 });//if we are exiting  the page, let's make the div with class .tour-page transparent in one second
    }
  }, [transitionStatus]);
  const [hasError, setErrors] = React.useState(false)
  const [dates, setDates] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  // const [which, setWhich] = useState('past')

  // async function fetchData(dates = 'upcoming') {
  //   const res = await fetch(
  //     `https://rest.bandsintown.com/artists/Sound%20Of%20ceres/events?app_id=61c7abec5a9262008d689d017eb2cb32&date=${dates}`
  //   )
  //   res
  //     .json()
  //     .then(res => {
  //       setDates(res)
  //       setLoading(false)
  //     })
  //     .catch(err => setErrors(err))
  // }

  // React.useEffect(() => {
  //   fetchData()
  // }, [])

  const songkick = '<a target="_blank" href="https://www.songkick.com/artists/8427818" class="songkick-widget" data-theme="light" data-track-button="on" data-detect-style="true" data-font-color="#000000" data-background-color="transparent" data-locale="en">Sound of Ceres tour dates</a> '


  // const renderedDates = dates.map((date, index) => {
  //   return (
  //     <a href={date.url} key={index} className="box" target="blank">

  //       <Typography component="p" className="date" variant="body2Black" color="primary.dark" >
  //         <Moment date={date.datetime} format="DD MMM YYYY  "></Moment>
  //       </Typography>
  //       <Typography component="p" variant="body2" className="venue" color="primary.dark" >
  //         {date.venue.name}
  //       </Typography>
  //       <Typography component="p" variant="body2Bold" className="city" color="primary.dark" >
  //         {date.venue.city}, {date.venue.region}
  //       </Typography>

  //     </a>
  //   )
  // })

  // const handlePast = e => {
  //   const which = e.currentTarget.dataset.which
  //   // toggleActive('which', e)
  //   setLoading(true)
  //   fetchData(which)
  // }

  // const handleUpcoming = e => {
  //   const which = e.currentTarget.dataset.which
  //   // toggleActive('which', e)
  //   setLoading(true)
  //   fetchData(which)
  // }
  return (
    <Layout currentPage={`tour`}>
      <Seo title="Tour" />
      <StaticImage
        alt="Karen - Photo by Michael McCleary"
        className="mobile hero"

        objectFit="cover"
        src={"../images/live-1.jpeg"}
      />
      <Container className={'tour-page'} maxWidth={false} >

        <div className="left">
          <Typography component="h1" variant="h1" color="primary.dark">
            Tour
          </Typography>





          {/* {loading ? (
            <Typography component="p" variant="body1" color="primary.dark" >
              Loading...
            </Typography>
          ) : <></>}

          <Typography component="p" variant="body1" color="primary.dark" >
            {dates.length === 0 && !loading ? 'Check back soon for new tour dates' : ''}
            {hasError ? JSON.stringify(hasError) : ''}
          </Typography> */}



          {/* <div className="dates">{renderedDates}</div> */}
          <div
            dangerouslySetInnerHTML={{ __html: songkick }}
          />
          <Script src="https://widget.songkick.com/8427818/widget.js" ></Script>
          {/* <button
            className="which active"
            onClick={handleUpcoming}
            data-which={'upcoming'}
          >
            Upcoming Dates
          </button>

          <button className="which" onClick={handlePast} data-which={'past'}>
            Past Dates
          </button> */}

        </div>
        <div className="right">
          <StaticImage
            alt="Karen - Photo by Michael McCleary"
           
            objectFit="cover"
            src={"../images/live-1.jpeg"}
  
          />
          {dates.length > 8 ? (
            <StaticImage
              alt="Karen - Photo by Michael McCleary"

              objectFit="cover"
              src={"../images/photo-page/n.jpg"}
    
            />
          ) : <></>}
       

        </div>



      </Container>

    </Layout>
  )
}

export default TourPage
